import React, {useState} from "react";
import { Helmet } from "react-helmet";

import Loader from './loader';
import LeadForm from './lead-form';

export default function Layout({pageClass,  children }) {
  const [openLeadForm, setOpenLeadForm] = useState(false);

  setTimeout(() => {
    setOpenLeadForm(true);
  }, 300000); // 5 min

  return (
    <div className={pageClass}>
      <Helmet htmlAttributes={{
          lang: 'en',
        }}>
        {/* <meta charSet="utf-8" /> */}
        <meta http-equiv="content-language" content="en-us" />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1.0" /> */}
      </Helmet>
      <LeadForm openModel={openLeadForm} />
      <Loader />
      {children}  
      <script
      key="fun_javascript"
      dangerouslySetInnerHTML={{
        __html: `
        (function(I, L, T, i, c, k, s) {if(I.iticks) return;I.iticks = {host:c, settings:s, clientId:k, cdn:L, queue:[]};var h = T.head || T.documentElement;var e = T.createElement(i);var l = I.location;e.async = true;e.src = (L||c)+'/client/inject-v2.min.js';h.insertBefore(e, h.firstChild);I.iticks.call = function(a, b) {I.iticks.queue.push([a, b]);};})(window, 'https://cdn-v1.intelliticks.com/prod/common', document, 'script', 'https://app.intelliticks.com', 'vpzxE4PgqML7GaRoj_c', {});
        `
      }}
      />  
    </div>
  )
}