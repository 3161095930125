import React from "react";
import {Modal} from "react-bootstrap";
import ContactForm from "./contact-form";

export default function ContactFormModel(props) {
    return (
        <Modal {...props} size="md" centered aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header closeButton style={{borderBottom: 0, paddingBottom: 0}}>
            </Modal.Header>
            
            <Modal.Body className="show-grid" style={{paddingTop: 0}}>
                <ContactForm container={{
                    style: {
                        padding: 0, border: 0
                    }
                }}></ContactForm>
            </Modal.Body>
        </Modal>
    );
}