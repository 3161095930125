import React, {useState} from "react";

import LoadingImage from "../images/loading.svg";

export default function Loader() {
    const [hideLoader, setHideLoader] = useState(false);

    setTimeout(() => {
        setHideLoader(true);
    }, 500);

    return !hideLoader ? (
        <div className="loader">
            <img src={LoadingImage} alt="Loader" />
        </div>
    ) : '';
}