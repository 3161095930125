import React from "react";
import {ListGroup} from "react-bootstrap";
import linkedin from '../images/icons/linkedin.svg';

export default function Footer() {
  return (
    <footer className="footer">
        <div className="container-fluid links-section">
            <div className="row">
                <div className="col col-12 col-md-4">
                    <div className="col-content">
                        <div className="col-heading">
                            Hire Developers
                        </div>
                        <ListGroup>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-nodejs-developers">Hire NodeJS Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-reactjs-developers">Hire ReactJS Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-react-native-developers">Hire React-Native Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-angularjs-developers">Hire Angular.js Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-vuejs-developers">Hire Vue.js Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-mean-stack-developers">Hire MEAN Stack Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-mern-stack-developers">Hire MERN Stack Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-graphql-developers">Hire GraphQL Developers</a>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
                <div className="col col-12 col-md-4">
                    <div className="col-content">
                        <div className="col-heading">
                            &nbsp;
                        </div>
                        <ListGroup>
                        <ListGroup.Item>
                                <a href="/hire-dedicated-mongodb-developers">Hire MongoDB Developers</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-product-development-team">Hire Product Development Team</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-web-app-development-team">Hire Web Development Team</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-mobile-app-development-team">Hire Mobile Development Team</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-chatbot-development-team">Hire Chatbot Development Team</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-messaging-app-development-team">Hire Messaging App Development Team</a>
                            </ListGroup.Item>
                            <ListGroup.Item>
                                <a href="/hire-dedicated-mvp-development-team">Hire MVP Development Team</a>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
                <div className="col col-12 col-md-4">
                    <div className="col-content">
                        <div className="col-heading">
                            Industries Experience
                        </div>
                        <ListGroup>
                            <ListGroup.Item>Automobile</ListGroup.Item>
                            <ListGroup.Item>Fintech</ListGroup.Item>
                            <ListGroup.Item>Education</ListGroup.Item>
                        </ListGroup>
                    </div>
                    <div className="col-content mt-5">
                        <div className="col-heading">
                            Contact Us
                        </div>
                        <ListGroup>
                            {/* <ListGroup.Item className="mb-3">
                                <div className="col-section-title font-weight-bold">Sales:</div>
                                <div>India: <a href="tel:+91-7976475525">+91-7976475525</a></div>
                            </ListGroup.Item> */}
                            <ListGroup.Item className="mb-3">
                                <div className="col-section-title font-weight-bold">For Business:</div>
                                <div><a href="mailto:onkar@mattecurve.com">onkar@mattecurve.com</a></div>
                            </ListGroup.Item>
                            <ListGroup.Item className="mb-3">
                                <div className="col-section-title font-weight-bold">For Job & Internship:</div>
                                <div><a href="mailto:hr@mattecurve.com">hr@mattecurve.com</a></div>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid copyright-section">
            <div className="row middle-xs">
                <div className="col col-md-8 col-sm-8 col-xs-12">
                    <ul className="social-link">
                        <li className="social-link-item menu-services">
                            <a href="https://www.linkedin.com/company/mattecurve">
                                <img src={linkedin}></img>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col col-md-4 col-sm-4 col-xs-12 text-right">
                    <span className="location-text">Jaipur, India</span>
                </div>
            </div>
            <div className="row middle-xs mt-2">
                <div className="col col-sm-6 copyright">
                    © 2021 MatteCurve Technologies Pvt. Ltd.
                </div>
            </div>
        </div>
        <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossOrigin="anonymous"></script>
    </footer>
  )
}