import React, {useState, useEffect} from "react";
import {ListGroup} from "react-bootstrap";
// import logo from '../images/logo.svg';
import logo from '../images/logo.png';

const showMobileMenu = () => {
    document.getElementById('menus-mobile-list').style.display = 'block';
};
const hideMobileMenu = () => {
    document.getElementById('menus-mobile-list').style.display = 'none';
};

export default function Header() {
  const [showServicesSubMenu, setShowServicesSubMenu] = useState(false);
  const [showServicesSubMenuElement, setShowServicesSubMenuElement] = useState(null);

  useEffect(() => {
    const closeMenu = (event) => {
        if (showServicesSubMenuElement && !showServicesSubMenuElement.contains(event.target)) {
            setShowServicesSubMenu(false);  
            document.removeEventListener('click', closeMenu);
        }
    };
    
    if (typeof window !== "undefined" && window.document) {
        document.addEventListener('click', closeMenu);
        return;
    }
  });

  return (
    <header className="header">
        <nav>
            <div className="container-fluid">
                <div className="row middle-xs">
                    <div className="col col-sm-2 col-md-4 col-md-3">
                        <div className="logo-block"> 
                            <a href="/" className="full-size">
                                <img src={logo} alt="MatteCurve Technologies Pvt. Ltd." />
                            </a>
                        </div>
                    </div>
                    <div className="col col-sm-10 col-md-8 text-right">
                        <ul id="menus" className="navigation">
                            <li className="menu-item menu-work"><a href="/">Home</a></li>
                            <li className="menu-item menu-work"><a href="/work">Work</a></li>
                            <li className="menu-item menu-services" onMouseEnter={(e) => setShowServicesSubMenu(true)} onMouseLeave={() => setShowServicesSubMenu(false)} ref={(element) => {
                                        setShowServicesSubMenuElement(element);
                                    }}>
                                <a href="/services" onClick={(e) => setShowServicesSubMenu(true)}>Services</a>
                                <div className={!showServicesSubMenu ? "services-sub-menu" : "services-sub-menu visible"} >
                                    <div>
                                        <div className="row">
                                            <div className="col col-5">
                                                <div className="heading">Web Development</div>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-nodejs-developers">NodeJS Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-reactjs-developers">ReactJS Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-angularjs-developers">AngularJS Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-vuejs-developers">VueJS Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-mean-stack-developers">MEAN STACK Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-mern-stack-developers">MERN STACK Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-graphql-developers">GraphQL Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-mongodb-developers">MongoDB Development</a>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                            <div className="col col-7">
                                                <div className="heading">Mobile App Development</div>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                    <a href="/hire-dedicated-react-native-developers">React-Native Development</a>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                <div className="heading mt-5">Other Developments</div>
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-product-development-team">Product Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-mvp-development-team">MVP Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-chatbot-development-team">Chatbot Development</a>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <a href="/hire-dedicated-messaging-app-development-team">Messaging App Development</a>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="menu-item menu-about"><a href="/about">About</a></li>
                            <li className="menu-item menu-contact"><a href="/contact">Contact Us</a></li>
                        </ul>
                        <div id="menus-mobile" className="mobile-navigation">
                            <div className="lines" role="button" tabIndex={0} onClick={() => showMobileMenu()} onKeyDown={() => showMobileMenu()}> 
                                <span></span> 
                                <span></span> 
                                <span></span> 
                                <span></span>
                            </div>
                            <br/>
                            <p>Menu</p>
                        </div>
                    </div>
                    <div id="menus-mobile-list">
                        <div className="close-menu" role="button" tabIndex={0} onClick={() => hideMobileMenu()} onKeyDown={() => hideMobileMenu()}>X</div>
                        <ul className="navigation">
                            <li className="menu-item animate__animated animate__fadeInUp menu-work"><a href="/">Home</a></li>
                            <li className="menu-item animate__animated animate__fadeInUp menu-work"><a href="/work">Work</a></li>
                            <li className="menu-item animate__animated animate__fadeInUp menu-services"><a href="/services">Services</a></li>
                            <li className="menu-item animate__animated animate__fadeInUp menu-about"><a href="/about">About</a></li>
                            <li className="menu-item animate__animated animate__fadeInUp menu-contact"><a href="/contact">Contact Us</a></li>
                            {/* <li className="menu-item animate__animated animate__fadeInUp menu-contact"><a href="https://www.mattecurve.com/insights/">Insights</a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
    </header>
  )
}