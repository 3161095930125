import React, {useState} from "react";
import axios from "axios";

export default function ContactForm(props) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    // const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [hasOptIn, setHasOptIn] = useState(true);

    const portalId = "25447811";
    const formId = "43d84fa6-90f0-4d46-9744-4e4a7500f322";

    const handleSubmit = e => {
        e.preventDefault()
        setIsLoading(true)
    
        axios
          .post(
            `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`,
            {
              portalId,
              formId,
              fields: [
                {
                  name: "email",
                  value: email,
                },
                {
                  name: "firstname",
                  value: name,
                },
                // {
                //   name: "mobilephone",
                //   value: phoneNumber,
                // },
                {
                  name: "message",
                  value: message,
                },
              ],
            }
          )
          .then(function(response) {
            setIsLoading(false);
            setIsSent(true);
          })
          .catch(function() {
            setIsLoading(false);
          });
      }

    return (
        <>
            <div className="contact-form-container" {...props.container}>
                {isSent && (
                    <p style={{fontWeight: "bold", color: "green" }}>
                        Thanks! We will get in touch with you within 24 hours.
                    </p>
                )}

                {!isSent && (
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="w-100 clearfix contact-form-header">
                        <h5 className="w-100">GET IN TOUCH WITH US</h5>
                        <div>
                          <small>
                            Start building your products today. Send us details and our expert will reach out to you within a few hours.</small>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">Your Full Name *</label>
                        <input className="form-control" name="name" id="name" type="text" required value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Your Business Email *</label>
                        <input type="email" name="email" className="form-control" id="email" aria-describedby="emailHelp" required value={email} onChange={e => setEmail(e.target.value)} />
                        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="phoneNumber">Your Phone Number</label>
                        <input type="number" name="phoneNumber" className="form-control" id="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="message">Message *</label>
                        <textarea className="form-control" name="message" id="message" rows="6" required placeholder="Tell us about your project" value={message} onChange={e => setMessage(e.target.value)}></textarea>
                    </div>
                    <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" id="checkbox" checked={hasOptIn} onChange={e => setHasOptIn(e.target.value)} disabled />
                        <label className="form-check-label" htmlFor="checkbox">Receive marketing communications.</label>
                    </div>
                    <button className="btn btn-primary mr-2" type="submit" disabled={isLoading}>
                        {isLoading ? "Sending…" : "Submit"}
                    </button>
                    <span>or</span> 
                    <a style={{color: "#04009A"}} target='_blank' className='ml-2' href="https://calendly.com/onkarjanwa/product-discussion">Schedule a call now</a>
                </form>
                )}
            </div>
        </>
    );
}